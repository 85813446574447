import Vue from 'vue'
import App from './App.vue'
import VueI18n from 'vue-i18n'
import messages from './lang/messages'
import vuetify from './plugins/vuetify' 
import router from './router'
import store from './store'
import Amplify, * as AmplifyModules from "aws-amplify";
import { AmplifyPlugin } from "aws-amplify-vue";

Amplify.configure({
  API: {
    endpoints: [
      {
        name: "orders",
        endpoint: process.env.VUE_APP_API_URL,
        region: process.env.VUE_APP_API_REGION,
      },
      {
        name: "returns",
        endpoint: process.env.VUE_APP_RETURN_API_URL,
        region: process.env.VUE_APP_API_REGION,
      },
    ],
  },
});

Vue.use(AmplifyPlugin, AmplifyModules);


Vue.config.productionTip = false

Vue.use(require("vue-moment"))

Vue.use(VueI18n)

let forcedLocale = new URLSearchParams(window.location.search).get('locale')
let locale = forcedLocale ? forcedLocale : navigator.language

export const i18n = new VueI18n({
  locale: locale.substr(0, 2),
  fallbackLocale: "en",
  messages
})


// Filters
Vue.filter("formatPrice", function(value, currency) {
  const priceMultiplier = currency ? Math.pow(10, currency.minor_unit) : 100;
  return (value / priceMultiplier).toFixed(2);
});

new Vue({
  router,
  vuetify,
  i18n,
  store,
  render: h => h(App)
}).$mount('#app')
