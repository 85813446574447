export default {
    order: {
        delivered:'delivered',
        deliveredPartially: 'delivered partially',
        homeDelivery: 'Home delivery',
        storeDelivery: 'Store delivery',
        relayPointDelivery: 'Relay point delivery',
        expressHomeDelivery: 'Express home delivery'
    },
    return: {
        numOrder: "n°order",
        type: "Type",
        date: "Date",
        products: "Products",
        amount: "Amount",
        status: "Status",
        productRef: "References",
        productSku: "SKU",
        productPrice: "Price",
        productColor: "Color",
        productSize: "Size",
        order: "order",
        missingProduct: "Missing products",
        clientChangeOfMind: "Client change his mind",
        deterioratedProducts: "Deteriorated products",
        otherReason: "Other reason",
        badSize: "Bad size",
        returnProducts: "Please select the products you want to return",
        returnQuantity: "quantity to return",
        motif: "Return reason",
        describeReason: "Describe reason",
        refundAmount: "Amount of refund",
        validate: "validate",
        close: "close",
        confirmationReturn: "Return confirmation",
        askValidationReturn: "Do you really want to return these products ?",
        errorConfirmation: "Please select quantity and the reason of the return",
        returnOrderLabel: "return of products from the order",
        quantityConfirmation: "Quantity confirmation",
        confirmedQuantity: "Confirmed quantity",
        returnedQuantity: "Returned quantity",
        quantity: "Quantity",
        returnableQuantity: "Returnable quantity",
        selectReturnReason: "Please select return reason",
        pleaseSpecify: "Please specify",
        indicateTheReason: "Indicate the reason",
        modifyTheReason: "Modify the reason",
        returnCreatedText: "Your return request has been taken into account",
        badColor: "Bad color",
        wrongProductReceived: "Wrong product received",
        productNotCorrespondingToThePhoto: "Product not corresponding to the photo",
        styleDoesNotSuitMe: "Style does not suit me",
        productTooBig: "Product too big",
        productTooSmall: "Product too small",
        client: "client",
        clientEmail: "email",
        clientPhone: "phone",
        reference: "reference",
        returnRecap: "Summary of your request: ",
        productAlreadyReturned: "All products in this order have already been returned",
        orderNotDelivered: "None of the products of the order have yet been delivered",
        transportCosts: "transport costs",
        productsTotal: "products total",
        defectiveProduct: "Defective product",
        lateDelivery: "Late delivery",
        disappointingQuality: "Disappointing quality",
        severalSizesOrdered: "Several sizes ordered",
        other: "Other",
        carrierType: "carrier type",
        deliveredQuantity: 'Delivered quantity',
        alreadyReturnedProducts: "Already returned products",
        blockAccessText: "Oops, it seems that the consultation time for this return has passed",
        blockReturnCreation: "You can no longer return any items on this order."
    },
    pageNotFound: {
        lostText: "Oops, it looks like you got lost"
    }
}
