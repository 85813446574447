export default{
    order: {
        delivered:'délivrée',
        deliveredPartially: 'délivrée partiellement',
        homeDelivery: 'Livraison à domicile',
        storeDelivery: 'Livraison en magasin',
        relayPointDelivery: 'Livraison en point relais',
        expressHomeDelivery: 'Livraison express à domicile'
    },
    return: {
        numOrder: "n°commande",
        type: "Type",
        date: "Date",
        products: "Produits",
        amount: "Montant",
        status: "Statut",
        productRef: "Référence",
        productSku: "SKU",
        productPrice: "Prix",
        productColor: "Couleur",
        productSize: "Taille",
        order: "Commande",
        missingProduct: "Produits/accessoires manquants",
        clientChangeOfMind: "Changement d'avis client",
        deterioratedProducts: "Produits détériorés",
        otherReason: "Autre raison",
        badSize: "La taille ne correspond pas",
        returnProducts: "Merci de sélectionner les articles que vous souhaitez nous retourner",
        returnQuantity: "Quantité à retourner",
        motif: "Motif du retour",
        describeReason: "Décrire la raison",
        refundAmount: "Total du remboursement",
        validate: "valider",
        close: "fermer",
        confirmationReturn: "Validation du retour",
        askValidationReturn: "Etes-vous sur de vouloir retourner ce ou ces produit(s) ?",
        errorConfirmation: "Veuillez sélectionner une quantité et un motif de retour",
        returnOrderLabel: "Retour des articles de la commande",
        quantityConfirmation: "Confirmation de la quantité",
        confirmedQuantity: "Quantité confirmée",
        returnedQuantity: "Quantité retournée",
        quantity: "Quantité",
        returnableQuantity: "Quantité retournable",
        selectReturnReason: "Veuillez sélectionner le motif de retour",
        pleaseSpecify: "Veuillez préciser",
        indicateTheReason: "Indiquer la raison",
        modifyTheReason: "Modifier la raison",
        returnCreatedText: "Votre de demande de retour à bien été prise en compte",
        badColor: "Mauvaise couleur",
        wrongProductReceived: "Pas reçu le bon produit",
        productNotCorrespondingToThePhoto: "Produit non conforme à la photo",
        styleDoesNotSuitMe: "Le style ne me va pas",
        productTooBig: "Produit trop grand",
        productTooSmall: "Produit trop petit",
        client: "client",
        clientEmail: "email",
        clientPhone: "telephone",
        reference: "référence",
        returnRecap: "Récapitulatif de votre demande: ",
        productAlreadyReturned: "Tous les produits de cette commande ont déja été retournés",
        transportCosts: "frais de transport",
        productsTotal: "total des produits",
        orderNotDelivered: "Aucun des produits de la commande n'a encore été livré",
        defectiveProduct: "Produit défectueux",
        lateDelivery: "Retard de livraison",
        disappointingQuality: "Qualité décevante",
        severalSizesOrdered: "Plusieurs tailles commandées",
        other: "Autre",
        carrierType: "type de livraison",
        deliveredQuantity: 'Quantité livrée',
        alreadyReturnedProducts: "Produits déjà retournés",
        blockAccessText: "Oups, il semblerait que le délai de consultation de ce retour soit dépassé",
        blockReturnCreation: "Vous ne pouvez plus retourner d'articles sur cette commande"
    },
    pageNotFound: {
        lostText: "Oups, il semblerait que vous vous soyez égaré"
    }
}
