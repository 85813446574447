<template>
  <v-container fluid v-if="returnOrder">
    <v-card outlined>
      <v-container fluid>
        <v-row>
          <v-col md="6">
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title class="text-uppercase">
                  {{ $t("return.reference") }}: {{ returnOrder.return_id }}
                </v-list-item-title>
                <v-list-item-subtitle class="text-uppercase">
                  {{ $t("return.order") }} N° {{ returnOrder.order_id }}
                </v-list-item-subtitle>
                <v-list-item-subtitle class="text-uppercase">
                  {{ $t("return.client") }}:
                  {{ returnOrder.customer.first_name }}
                  {{ returnOrder.customer.last_name }}
                </v-list-item-subtitle>
                <v-list-item-subtitle class="text-uppercase">
                  {{ $t("return.clientEmail") }}: {{ returnOrder.customer.email }}
                </v-list-item-subtitle>
                <v-list-item-subtitle class="text-uppercase" v-if="returnOrder.customer.phone"
                  >{{ $t("return.clientPhone") }}: {{ returnOrder.customer.phone }}
                </v-list-item-subtitle>
                <v-list-item-subtitle
                  v-if="returnOrder.current_status == 'cancelled' && returnOrder.cancel_reason">
                  {{ $t("return.cancelReason") }}: {{ returnOrder.cancel_reason }}
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-col>
          <v-col md="6" class="text-right">
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title
                  class="text-uppercase"
                  v-if="returnOrder.date">
                  {{ $t("return.date") }}:
                  {{ new Date(returnOrder.date) | moment("DD/MM/YYYY HH:mm:ss") }}
                </v-list-item-title>

                <v-list-item-subtitle class="text-uppercase">
                  {{ $t("return.products") }}: {{ totalNbProduct }}
                </v-list-item-subtitle>
                <v-list-item-subtitle class="text-uppercase">
                  {{ $t("return.amount") }}:
                  {{ returnOrder.amount | formatPrice(returnOrder.currency) }}
                  {{ returnOrder.currency.code }}
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-col>
        </v-row>
        <v-divider></v-divider>
        <v-row v-if="$vuetify.breakpoint.width >= 700">
          <v-col md="12">
            <v-list
              v-for="(item, index) in returnOrder.items"
              :key="item.return_id">
              <v-list-item>
                <v-list-item-avatar tile size="100">
                  <v-img :src="item.product_image_link || defaultImage"></v-img>
                </v-list-item-avatar>
                <v-list-item-content class="pb-2 pt-0">
                  <v-list-item-content class="font-weight-bold">{{item.product_title}}</v-list-item-content>
                  <v-list-item-subtitle>
                    {{ $t("return.productRef") }}: {{ item.product_ean }}
                  </v-list-item-subtitle>
                  <v-list-item-subtitle v-if="item.product_sku">
                    {{ $t("return.productSku") }}: {{ item.product_sku }}
                  </v-list-item-subtitle>
                  <v-list-item-subtitle>
                    {{ $t("return.productPrice") }}:
                    {{item.product_sale_price | formatPrice(returnOrder.currency)}}
                    {{ returnOrder.currency.code }}
                  </v-list-item-subtitle>
                  <v-list-item-subtitle v-if="item.product_color">
                    {{ $t("return.productColor") }}: {{ item.product_color }}
                  </v-list-item-subtitle>
                  <v-list-item-subtitle v-if="item.product_size">
                    {{ $t("return.productSize") }}: {{ item.product_size }}
                  </v-list-item-subtitle>
                  <v-list-item-subtitle v-if="item.return_quantity">
                    {{ $t("return.returnedQuantity") }}: {{ item.return_quantity }}
                  </v-list-item-subtitle>
                </v-list-item-content>
                <v-list-item-action class="align-right">
                  <span class="primary--text text-right text-uppercase">
                    {{ getReturnReasonTraduction(item.return_reason) }}
                  </span>
                </v-list-item-action>
              </v-list-item>
              <v-divider
                :key="index"
                v-if="index != returnOrder.items.length - 1"></v-divider>
            </v-list>
          </v-col>
        </v-row>

        <!-- card display -->
        <v-row v-else justify="center">
          <v-col cols="12">
            <v-card
              outlined
              v-for="(item, index) in returnOrder.items"
              :key="index"
              class="mx-auto my-4"
              max-width="280"
            >
              <v-img
                :src="item.product_image_link || defaultImage"
                max-height="262"
                width="100%"
              ></v-img>

              <v-card-text class="font-weight-bold subtitle-2 text-center text--primary">
                {{item.product_title}}
              </v-card-text>
              <v-col>
                <v-row>
                  <v-col class="pb-0 pt-0"
                    ><v-card-text class="pa-0"
                      >{{ $t("return.productRef") }}:
                    </v-card-text></v-col
                  >
                  <v-col class="pb-0 pt-0 text-right"
                    ><v-card-text class="pa-0">{{
                      item.product_ean
                    }}</v-card-text></v-col
                  >
                </v-row>
                <v-row v-if="item.product_sku">
                  <v-col class="pb-0 pt-0"
                    ><v-card-text class="pa-0"
                      >{{ $t("return.productSku") }}:
                    </v-card-text></v-col
                  >
                  <v-col class="pb-0 pt-0 text-right"
                    ><v-card-text class="pa-0">{{
                      item.product_sku
                    }}</v-card-text></v-col
                  >
                </v-row>
                <v-row>
                  <v-col class="pb-0 pt-0"
                    ><v-card-text class="pa-0"
                      >{{ $t("return.productPrice") }}:
                    </v-card-text></v-col
                  >
                  <v-col class="pb-0 pt-0 text-right"
                    ><v-card-text class="pa-0">
                      {{ item.product_sale_price | formatPrice(returnOrder.currency) }} {{ returnOrder.currency.code }}
                    </v-card-text>
                  </v-col>
                </v-row>
                <v-row v-if="item.product_color">
                  <v-col class="pb-0 pt-0" md="6"
                    ><v-card-text class="pa-0"
                      >{{ $t("return.productColor") }}:
                    </v-card-text></v-col
                  >
                  <v-col class="pb-0 pt-0 text-right "
                    ><v-card-text class="pa-0">
                      {{ item.product_color }}
                    </v-card-text>
                  </v-col>
                </v-row>
                <v-row v-if="item.product_size">
                  <v-col class="pb-0 pt-0 "
                    ><v-card-text class="pa-0"
                      >{{ $t("return.productSize") }}:
                    </v-card-text></v-col
                  >
                  <v-col class="pb-0 pt-0 text-right"
                    ><v-card-text class="pa-0">
                      {{ item.product_size }}
                    </v-card-text>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col class="pb-0 pt-0"
                    ><v-card-text class="pa-0"
                      >{{ $t("return.quantity") }}:
                    </v-card-text></v-col
                  >
                  <v-col class="pb-0 pt-0 text-right"
                    ><v-card-text class="pa-0">
                      {{ item.return_quantity }}
                    </v-card-text>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col class="pb-0 pt-0"
                    ><v-card-text
                      align="center"
                      class="pa-0 primary--text"
                      >{{ getReturnReasonTraduction(item.return_reason) }}</v-card-text
                    ></v-col
                  >
                </v-row>
              </v-col>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </v-card>
  </v-container>
</template>
<script>
// import { EventBus } from "../../event-bus";
import { mapState } from 'vuex';

export default {
  name: "ReturnSummary",
  props: {
    returnOrder: {
      type: Object,
      required: true
    },
  },
  data() {
    return {
      returnReasons: [
        { text: this.$t("return.styleDoesNotSuitMe"), value: "style_does_not_suit_me" },
        { text: this.$t("return.productTooBig"), value: "product_too_big" },
        { text: this.$t("return.productTooSmall"), value: "product_too_small" },
        { text: this.$t("return.productNotCorrespondingToThePhoto"), value: "product_not_corresponding_to_the_photo" },
        { text: this.$t("return.lateDelivery"), value: "late_delivery" },
        { text: this.$t("return.disappointingQuality"), value: "disappointing_quality" },
        { text: this.$t("return.severalSizesOrdered"), value: "several_sizes_ordered" },
        { text: this.$t("return.defectiveProduct"), value: "defective_product" },    
        { text: this.$t("return.other"), value: "other" },
      ],
      cancelReasons: [
        {
          text: this.$t("return.clientChangeOfMind"),
          value: "client_changed_his_mind",
        },
        {
          text: this.$t("return.deterioratedProducts"),
          value: "deteriorated_products",
        },
        { text: this.$t("return.missingProduct"), value: "missing_product" },
        { text: this.$t("return.otherReason"), value: "other" },
      ],
      defaultImage: require("../assets/img/products/product_default.png"),
    };
  },
  mounted() {
    // this.get();
  },
  methods: {
    getReturnReasonTraduction(returnReasonValue) {
      var returnReason = this.returnReasons.find((returnReason) => returnReason.value == returnReasonValue);
      if (returnReason) {
        return returnReason.text;
      } else return returnReasonValue;
    },
    getCancelReasonTraduction(cancelReasonValue) {
      var cancelReason = this.cancelReasons.find(
        (cancelReason) => cancelReason.value == cancelReasonValue
      );
      if (cancelReason) {
        return cancelReason.text;
      } else return cancelReasonValue;
    },
    get: function () {
      let path = "/v1/int/returns/" + this.$route.params.id;
      let myInit = {
        headers: {
          Authorization: "Bearer " + this.$auth.accessToken,
        },
        queryStringParameters: {}
      };
      if(!this.isCustomerService){
        myInit.queryStringParameters.originLocationId = this.currentStore
      }
      this.$Amplify.API.get("returns", path, myInit)
        .then((res) => {
          this.returnOrder = res;
          this.loadingBtn = false;
        })
        .catch((e) => {
          alert(e);
          // EventBus.$emit("displaySnackbar", {
          //     display: true,
          //     text: "error during return loading",
          //     color: "error"
          // });
        });
    },
  },
  computed: {
    totalNbProduct() {
      let nbProduct = 0;
      for (let item of this.returnOrder.items) {
        nbProduct += item.return_quantity;
      }
      return nbProduct;
    },
    ...mapState(["currentStore", "isCustomerService"])
  },
};
</script>

<style>
</style>