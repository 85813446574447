<template>
  <v-row justify="center">
    <v-dialog v-model="show" max-width="400px" persistent>
      <v-card>
        <v-card-title style="justify-content: center;">{{ $t("return.motif") }}</v-card-title>
        <v-card-text>
          <v-card-text class="pb-0">{{$t('return.selectReturnReason')}}</v-card-text>
          <v-radio-group v-model="returnReason" column>
            <v-radio
              v-for="choice of choices"
              v-bind:key="choice.value"
              :label="choice.text"
              :value="choice.value"
            ></v-radio>
          <v-text-field dense v-model="otherChoice" v-if="returnReason == 'other'" :placeholder="$t('return.pleaseSpecify')"></v-text-field>
          </v-radio-group>
        </v-card-text>
        <v-card-actions class="pa-0">
          <v-btn
            color="primary"
            tile
            :disabled="returnReason == '' || returnReason == 'other' && otherChoice.trim() == ''"
            style="width: 50%" 
            @click="confirm()"
          >{{$t('return.validate')}}</v-btn>
          <v-btn
            color="secondary"
            tile
            class="ml-0"
            style="width: 50%"
            @click="close()"
          >{{$t('return.close')}}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>


<script>
export default {
  name: "ReturnReasonModal",
  data() {
    let initData = this.getOtherValue(this.previousReason)
    return {
     otherChoice: initData.otherChoice,
     returnReason: initData.returnReason,    
    };
  },
  props: {
    previousReason: {
      type: String,
    },
    value: {
      type: Boolean,
      default: false
    },
    choices: {
      type: Array,
      required: true,
    }
  },
  computed: {
    show: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      }
    },
  },
  methods: {
    confirm() {
      if(this.returnReason == "other" && this.otherChoice.trim() != ""){
        this.$emit("confirm", this.otherChoice);
        this.show = false;
      }else{
        this.$emit("confirm", this.returnReason);
        this.show = false;
      }
    },
    close(){
      this.$emit("close")
      this.show = false;
    },
    getOtherValue(returnReason){
      console.log(1, returnReason)
      let otherChoice = ""
      if(returnReason != "" && !this.choices.some((choice)=> {
          return choice.value == returnReason
        })){
        otherChoice = returnReason
        returnReason = "other"
      }
      return {returnReason, otherChoice}
    }
  }
};
</script>
<style scoped>
.w-45 {
  width: 45%;
}
</style>