<template>
 <v-row justify="center">
    <v-dialog v-model="show" max-width="300px">
      <v-card>
        <v-card-title style="justify-content: center;">{{ $t("return.confirmationReturn") }}</v-card-title>
        <v-card-text class="pb-0">{{ $t("return.askValidationReturn")}}</v-card-text>
        <v-card-actions class="pa-0 mt-4">
          <v-btn
            color="primary"
            tile
            @click="validate()"
            style="width: 50%;"
          >{{$t('return.validate')}}</v-btn>
          <v-btn
            color="secondary"
            tile
            class="ml-0"
            style="width: 50%;"
            @click="close()"
          >{{$t("return.close")}}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
export default {
 name: "ConfirmationReturns",
 props: {
     value: {
            required: true
        }
 },
 data(){
     return{

     }
 },
 computed: {
        show: {
            get () {
                return this.value
            },
            set (value) {
                this.$emit('input', value)
            }
        }
    },
    methods: {
        validate(){
            this.$emit("validate")
        },
        close(){
            this.$emit("close")
        },
    }
}
</script>

<style>

</style>