<template>
  <v-container fluid class="d-flex justify-center align-center" style="height: 80vh;">
    <h1 style="text-align: center;">{{$t('pageNotFound.lostText')}}</h1>
  </v-container>
</template>

<script>

export default {
  name: "PageNotFound",
  data() {return {}}
};
</script>