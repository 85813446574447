<template>
  <v-app style="font-size:14px">
    <v-app-bar class="ma-0" dense app flat color="#fff">
        <v-container class="px-0 align-center d-flex flex-column">
            <v-img
              :src="logoOctipas" max-width="110px" height="auto"
            ></v-img>
        </v-container>
    </v-app-bar>
     <v-main class="pt-11">
       <v-container fluid class="pa-0 t-0pb-12 d-flex flex-column justify-center" style="max-width:1200px">
        <router-view></router-view>
       </v-container>
     </v-main>
  </v-app>
</template>

<script>
import Vue from 'vue'

export default  Vue.extend({
name: 'App',
data(){
  return {
    //logoOctipas: require("@/assets/logo-octipas-medium-by.png"),
    logoOctipas: "https://octipas-sftp.s3-eu-west-1.amazonaws.com/KENZO/STAGING/images/logo_kenzo_mail_2022.png"
  }
}
})
</script>

<style>
</style>
