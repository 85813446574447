<template>
  <v-container fluid>
    <v-container fluid class="d-flex align-center flex-column px-3">
      <template v-if="returnSummary" fluid class="d-flex flex-column" style="height: 100%;">
        <h1 style="text-align: center;">{{ $t("return.returnCreatedText") }}</h1>
        <v-card-text class="pb-0">{{ $t("return.returnRecap") }}</v-card-text>
        <return-detail class="pt-0" :returnOrder="returnSummary"></return-detail>
      </template>
      <v-card outlined v-else-if="order">
        <v-container>
          <v-row>
            <v-col md="12">
              <v-toolbar v-if="$vuetify.breakpoint.width > 700" flat dense>
                 <v-spacer />
                  <h3 class="text-uppercase text-center">
                    {{ $t("return.returnOrderLabel") }} {{ order.order_id }}
                  </h3>
                 <v-spacer />
                <v-select
                  style="max-width: 95px;"
                  outlined
                  dense
                  :disabled="loading"
                  v-model="locale"
                  @change="reloadPage()"
                  :items="languages"
                  hide-details
                  :light="true"
                ></v-select>
              </v-toolbar>
              <span v-else >
                <v-list-item>
                  <v-list-item-content>
                    <h3 class="text-uppercase text-center">
                      {{ $t("return.returnOrderLabel") }} {{ order.order_id }}
                    </h3>
                  </v-list-item-content>
                  </v-list-item>
                  <v-toolbar flat dense>
                    <v-spacer />
                      <v-select
                        style="max-width: 95px;"
                        outlined
                        dense
                        :disabled="loading"
                        v-model="locale"
                        @change="reloadPage()"
                        :items="languages"
                        hide-details
                        :light="true"
                      ></v-select>
                      <v-spacer />
                  </v-toolbar>
              </span>
              <v-card-text class="text-center" v-if="!['delivered','partially_delivered'].includes(order.current_status)">{{$t("return.orderNotDelivered")}}</v-card-text>
              <v-card-text class="text-center" v-else-if="returnableProducts.length == 0">{{$t("return.productAlreadyReturned")}}</v-card-text>
              <v-list-item class="text-right" v-else>
                <v-list-item-content>
                  <v-list-item-title class="text-uppercase" v-if="order.date"
                    >{{ $t("return.date") }}:
                    {{ new Date(order.date) | moment("DD/MM/YYYY HH:mm:ss") }}
                  </v-list-item-title>
                  <v-list-item-subtitle class="text-uppercase"
                    >{{ $t("return.products") }}:
                    {{ getNbProducts() }}
                  </v-list-item-subtitle>
                  <v-list-item-subtitle class="text-uppercase"
                    >{{ $t("return.amount") }}:
                    {{ order.total_price | formatPrice(order.currency) }}
                    {{ order.currency ? order.currency.code : order.currency_code }}
                  </v-list-item-subtitle>
                  <v-list-item-subtitle class="text-uppercase"
                    >{{ $t("return.status") }}:
                    {{ getStatus(order.current_status) }}
                  </v-list-item-subtitle>
                  <v-list-item-subtitle v-if="order.carrier && order.carrier.carrier_type" class="text-uppercase"
                    >{{ $t("return.carrierType") }}:
                    {{ getCarrierTypeTranslation(order.carrier.carrier_type) }}
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-col>
          </v-row>
          <span v-if="['delivered','partially_delivered'].includes(order.current_status) && returnableProducts.length > 0">
            <v-alert class="text-center"  type="warning"  text outlined v-if="blockReturnCreation">{{$t("return.blockReturnCreation")}}</v-alert>
            <v-card-text class="pb-0"> {{ $t("return.returnProducts") }}: </v-card-text>
            <!-- <v-toolbar flat dense>
              <v-card-text class="pb-0"> {{ $t("return.returnProducts") }}: </v-card-text>
              <v-spacer />
              <v-select

                style="width: 91px;"
                outlined
                dense
                v-model="locale"
                @change="reloadPage()"
                :items="languages"
                hide-details
                :light="true"
              ></v-select>
            </v-toolbar> -->
            <!-- list display -->
            <v-row v-if="$vuetify.breakpoint.width > 700">
              <v-col>
                <v-divider></v-divider>
                <v-list class="pt-0">
                  <template v-for="item in returnableProducts">
                    <span :key="item.item_id">
                      <v-list-item class="mb-2 mt-2">
                        <v-list-item-avatar tile size="100">
                          <v-img
                            :src="item.product_image_link || defaultImage">
                          </v-img>
                        </v-list-item-avatar>
                        <v-list-item-content class="mb-0 pa-0">
                          <v-list-item-content class="pb-2 pt-0 font-weight-bold">
                            {{ item.product_title }}
                          </v-list-item-content>
                          <v-list-item-subtitle
                            >{{ $t("return.productRef") }}:
                            {{ item.product_ean }}
                          </v-list-item-subtitle>
                          <v-list-item-subtitle v-if="item.product_sku"
                            >{{ $t("return.productSku") }}:
                            {{ item.product_sku }}
                          </v-list-item-subtitle>
                          <v-list-item-subtitle
                            >{{ $t("return.productPrice") }}:
                            {{ item.product_sale_price.toFixed(2) }}
                            {{ order.currency ? order.currency.code : order.currency_code}}
                          </v-list-item-subtitle>
                          <v-list-item-subtitle
                            v-if="item.product_color"
                            >{{ $t("return.productColor") }}:
                            {{ item.product_color }}</v-list-item-subtitle
                          >
                          <v-list-item-subtitle
                            v-if="item.product_size"
                            >{{ $t("return.productSize") }}:
                            {{ item.product_size }}</v-list-item-subtitle
                          >
                          <v-list-item-subtitle
                            >{{ $t("return.deliveredQuantity") }}:
                            {{ item.validated_quantity }}</v-list-item-subtitle
                          >
                          <v-list-item-subtitle
                            >{{ $t("return.returnableQuantity") }}:
                            {{ item.returnable_quantity }}</v-list-item-subtitle
                          >
                        </v-list-item-content>
                        <v-list-item-action
                          class="align-right"
                          style="max-width: 260px"
                        >
                          <v-col>
                            <v-select
                              outlined
                              dense
                              v-model="item.return_quantity"
                              :label="$t('return.returnedQuantity')"
                              :items="getNbItems(item.returnable_quantity)"
                              hide-details
                              :light="true"
                              :disabled="blockReturnCreation"
                            ></v-select>
                            <v-btn
                              color="primary"
                              class="mt-2 mb-2"
                              block
                              depressed
                              tile
                              :disabled="item.return_quantity < 1 || loading || blockReturnCreation "
                              @click="openProductReturnReasonModal(item)"
                            >
                              <span v-if="item.return_reason">{{
                                $t("return.modifyTheReason")
                              }}</span>
                              <span v-else>{{
                                $t("return.indicateTheReason")
                              }}</span>
                            </v-btn>
                            <v-card-text
                              v-if="
                                item.return_quantity > 0 && item.return_reason
                              "
                              class="pt-0 text-center"
                            >
                              {{ getReturnReasonTraduction(item.return_reason) }}
                            </v-card-text>
                          </v-col>
                        </v-list-item-action>
                      </v-list-item>
                      <v-row v-if="item.return_quantity > 0 && !item.return_reason">
                        <v-col class="text-center pb-0 pt-0">
                          <v-card-text class="red--text pb-0 pt-0">{{$t("return.errorConfirmation")}}</v-card-text>
                        </v-col>
                      </v-row>
                      <v-divider></v-divider>
                    </span>
                  </template>
                </v-list>
              </v-col>
            </v-row>
            <!-- card display -->
            <v-row v-else justify="center">
              <v-col cols="12">
                <v-card
                  outlined
                  :key="item.item_id"
                  v-for="item in returnableProducts"
                  class="mx-auto my-4"
                  max-width="280">
                    <v-img
                      class="mx-auto"
                      :src="item.product_image_link || defaultImage"
                      max-height="262"
                      max-width="100%">
                    </v-img>
                    <v-card-text class="font-weight-bold subtitle-2 text-center text--primary">
                      {{item.product_title}}
                    </v-card-text>
                    <v-col>
                      <v-row>
                        <v-col class="pb-0 pt-0" md="6"
                          ><v-card-text class="pa-0"
                            >{{ $t("return.productRef") }}:</v-card-text
                          ></v-col
                        >
                        <v-col class="pb-0 pt-0 text-right" md="6"
                          ><v-card-text class="pa-0">{{
                            item.product_ean
                          }}</v-card-text></v-col
                        >
                      </v-row>
                      <v-row v-if="item.product_sku">
                        <v-col class="pb-0 pt-0" md="6"
                          ><v-card-text class="pa-0"
                            >{{ $t("return.productSku") }}:</v-card-text
                          ></v-col
                        >
                        <v-col class="pb-0 pt-0 text-right" md="6"
                          ><v-card-text class="pa-0">{{
                            item.product_sku
                          }}</v-card-text></v-col
                        >
                      </v-row>
                      <v-row>
                        <v-col class="pb-0 pt-0" md="6"
                          ><v-card-text class="pa-0"
                            >{{ $t("return.productPrice") }}:</v-card-text
                          ></v-col
                        >
                        <v-col class="pb-0 pt-0 text-right" md="6"
                          ><v-card-text class="pa-0">
                            {{ item.product_sale_price.toFixed(2) }} {{ order.currency ? order.currency.code : order.currency_code}}
                          </v-card-text>
                        </v-col>
                      </v-row>
                      <v-row v-if="item.product_color">
                        <v-col class="pb-0 pt-0" md="6"
                          ><v-card-text class="pa-0"
                            >{{ $t("return.productColor") }}:</v-card-text
                          ></v-col
                        >
                        <v-col class="pb-0 pt-0 text-right" md="6"
                          ><v-card-text class="pa-0">
                            {{ item.product_color }}
                          </v-card-text>
                        </v-col>
                      </v-row>
                      <v-row v-if="item.product_size">
                        <v-col class="pb-0 pt-0" md="6"
                          ><v-card-text class="pa-0"
                            >{{ $t("return.productSize") }}:</v-card-text
                          ></v-col
                        >
                        <v-col class="pb-0 pt-0 text-right" md="6"
                          ><v-card-text class="pa-0">
                            {{ item.product_size }}
                          </v-card-text>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col class="pb-0 pt-0" md="6"
                          ><v-card-text class="pa-0"
                            >{{ $t("return.deliveredQuantity") }}:</v-card-text
                          ></v-col
                        >
                        <v-col class="pb-0 pt-0 text-right" md="6"
                          ><v-card-text class="pa-0">
                            {{ item.validated_quantity }}
                          </v-card-text>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col class="pb-0 pt-0" md="6"
                          ><v-card-text class="pa-0"
                            >{{ $t("return.returnableQuantity") }}:</v-card-text
                          ></v-col
                        >
                        <v-col class="pb-0 pt-0 text-right" md="6"
                          ><v-card-text class="pa-0">
                            {{ item.returnable_quantity }}
                          </v-card-text>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col class="pb-0">
                          <v-select
                            outlined
                            hide-details
                            dense
                            v-model="item.return_quantity"
                            :label="$t('return.returnedQuantity')"
                            :items="getNbItems(item.returnable_quantity)"
                            :disabled="blockReturnCreation"
                          ></v-select>
                          <v-btn
                            color="primary"
                            class="mt-2 mb-2"
                            block
                            depressed
                            tile
                            :disabled="item.return_quantity < 1 || loading || blockReturnCreation"
                            @click="openProductReturnReasonModal(item)"
                          >
                            <span v-if="item.return_reason">{{
                              $t("return.modifyTheReason")
                            }}</span>
                            <span v-else>{{ $t("return.indicateTheReason") }}</span>
                          </v-btn>
                          <v-card-text
                            class="text-center pt-0 pb-0 subtitle-2 font-weight-bold"
                            v-if="item.return_quantity > 0 && item.return_reason"
                          >
                            {{
                              getReturnReasonTraduction(item.return_reason)
                            }}</v-card-text
                          >
                        </v-col>
                      </v-row>
                      <v-row
                        justify="center"
                        v-if="item.return_quantity > 0 && !item.return_reason">
                        <v-col class="text-center pb-0 pt-0">
                          <v-card-text class="red--text pt-0">{{
                            $t("return.errorConfirmation")
                          }}</v-card-text>
                        </v-col>
                      </v-row>
                    </v-col>
                </v-card>
              </v-col>
            </v-row>
            <!-- footer -->
            <v-row>
              <v-col class="d-flex justify-end">
                <v-simple-table dense id="summary-table">
                      <tbody>
                          <tr v-if="refundTransport">
                              <td class="mid-row text-right pl-0 pr-1">{{ $t("return.productsTotal") }}:</td>
                              <td class="mid-row text-right pl-0 pr-0">
                                {{(refund - order.carrier.cost).toFixed(2)}}
                                {{ order.currency ? order.currency.code : order.currency_code}}
                              </td>
                          </tr>
                          <tr v-if="refundTransport">
                              <td class='text-right pl-0 pr-1'>{{ $t("return.transportCosts") }}:</td>
                              <td class='text-right pl-0 pr-0'>
                                {{order.carrier.cost.toFixed(2)}}
                                {{ order.currency ? order.currency.code : order.currency_code}}
                              </td>
                          </tr>
                          <tr>
                              <td class='text-right pl-0 pr-1'>{{ $t("return.refundAmount") }}: </td>
                              <td class='text-right pl-0 pr-0'>
                                {{ refund.toFixed(2) }}
                                {{ order.currency ? order.currency.code : order.currency_code}}
                              </td>
                          </tr>
                      </tbody>
                </v-simple-table>
              </v-col>
            </v-row>
            <v-row justify="center">
              <v-col class="text-center">
                <v-btn
                  :loading="loading"
                  color="primary"
                  :disabled="selectedItems.length == 0 || missingReason || blockReturnCreation"
                  depressed
                  tile
                  @click="openConfirmationDialog"
                >
                  {{ $t("return.validate") }}
                </v-btn>
              </v-col>
            </v-row>
          </span>
          <span v-if="alreadyReturnedProducts && alreadyReturnedProducts.length">
            <v-card-text class="pb-0"> {{ $t("return.alreadyReturnedProducts") }}: </v-card-text>
            <!-- list display -->
            <v-row v-if="$vuetify.breakpoint.width > 700">
              <v-col>
                <v-divider></v-divider>
                <v-list class="pt-0">
                  <template v-for="item in alreadyReturnedProducts">
                    <span :key="item.item_id">
                      <v-list-item class="mb-2 mt-2" disabled>
                        <v-list-item-avatar tile size="100">
                          <v-img
                            style = "opacity: .6;"
                            :src="item.product_image_link || defaultImage">
                          </v-img>
                        </v-list-item-avatar>
                        <v-list-item-content class="mb-0 pa-0">
                          <v-list-item-content class="pb-2 pt-0 font-weight-bold">
                            {{ item.product_title }}
                          </v-list-item-content>
                          <v-list-item-subtitle class="returned-text"
                            >{{ $t("return.productRef") }}:
                            {{ item.product_ean }}
                          </v-list-item-subtitle>
                          <v-list-item-subtitle v-if="item.product_sku" class="returned-text"
                            >{{ $t("return.productSku") }}:
                            {{ item.product_sku }}
                          </v-list-item-subtitle>
                          <v-list-item-subtitle class="returned-text"
                            >{{ $t("return.productPrice") }}:
                            {{ item.product_sale_price.toFixed(2) }}
                            {{ order.currency ? order.currency.code : order.currency_code}}
                          </v-list-item-subtitle>
                          <v-list-item-subtitle class="returned-text"
                            v-if="item.product_color"
                            >{{ $t("return.productColor") }}:
                            {{ item.product_color }}</v-list-item-subtitle
                          >
                          <v-list-item-subtitle class="returned-text"
                            v-if="item.product_size"
                            >{{ $t("return.productSize") }}:
                            {{ item.product_size }}</v-list-item-subtitle
                          >
                          <v-list-item-subtitle class="returned-text"
                            >{{ $t("return.returnedQuantity") }}:
                            {{ item.returned_quantity_to_display }}</v-list-item-subtitle
                          >
                        </v-list-item-content>
                      </v-list-item>
                      <v-divider></v-divider>
                    </span>
                  </template>
                </v-list>
              </v-col>
            </v-row>
            <!-- card display -->
            <v-row v-else justify="center">
              <v-col cols="12">
                <v-card
                  disabled
                  outlined
                  :key="item.item_id"
                  v-for="item in alreadyReturnedProducts"
                  class="mx-auto my-4"
                  max-width="280">
                    <v-img
                      class="mx-auto"
                      :src="item.product_image_link || defaultImage"
                      max-height="262"
                      max-width="100%">
                    </v-img>
                    <v-card-text class="font-weight-bold subtitle-2 text-center text--primary">
                      {{item.product_title}}
                    </v-card-text>
                    <v-col>
                      <v-row>
                        <v-col class="pb-0 pt-0" md="6"
                          ><v-card-text class="pa-0"
                            >{{ $t("return.productRef") }}:</v-card-text
                          ></v-col
                        >
                        <v-col class="pb-0 pt-0 text-right" md="6"
                          ><v-card-text class="pa-0">{{
                            item.product_ean
                          }}</v-card-text></v-col
                        >
                      </v-row>
                      <v-row v-if="item.product_sku">
                        <v-col class="pb-0 pt-0" md="6"
                          ><v-card-text class="pa-0"
                            >{{ $t("return.productSku") }}:</v-card-text
                          ></v-col
                        >
                        <v-col class="pb-0 pt-0 text-right" md="6"
                          ><v-card-text class="pa-0">{{
                            item.product_sku
                          }}</v-card-text></v-col
                        >
                      </v-row>
                      <v-row>
                        <v-col class="pb-0 pt-0" md="6"
                          ><v-card-text class="pa-0"
                            >{{ $t("return.productPrice") }}:</v-card-text
                          ></v-col
                        >
                        <v-col class="pb-0 pt-0 text-right" md="6"
                          ><v-card-text class="pa-0">
                            {{ item.product_sale_price.toFixed(2) }} {{ order.currency ? order.currency.code : order.currency_code}}
                          </v-card-text>
                        </v-col>
                      </v-row>
                      <v-row v-if="item.product_color">
                        <v-col class="pb-0 pt-0" md="6"
                          ><v-card-text class="pa-0"
                            >{{ $t("return.productColor") }}:</v-card-text
                          ></v-col
                        >
                        <v-col class="pb-0 pt-0 text-right" md="6"
                          ><v-card-text class="pa-0">
                            {{ item.product_color }}
                          </v-card-text>
                        </v-col>
                      </v-row>
                      <v-row v-if="item.product_size">
                        <v-col class="pb-0 pt-0" md="6"
                          ><v-card-text class="pa-0"
                            >{{ $t("return.productSize") }}:</v-card-text
                          ></v-col
                        >
                        <v-col class="pb-0 pt-0 text-right" md="6"
                          ><v-card-text class="pa-0">
                            {{ item.product_size }}
                          </v-card-text>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col class="pb-0 pt-0" md="6"
                          ><v-card-text class="pa-0"
                            >{{ $t("return.returnedQuantity") }}:</v-card-text
                          ></v-col
                        >
                        <v-col class="pb-0 pt-0 text-right" md="6"
                          ><v-card-text class="pa-0">
                            {{ item.returned_quantity_to_display }}
                          </v-card-text>
                        </v-col>
                      </v-row>
                    </v-col>
                </v-card>
              </v-col>
            </v-row>
          </span>
        </v-container>
      </v-card>
    </v-container>
    <confirmation-returns
      v-model="showConfirmationDialog"
      @validate="sendAllData"
      @close="closeConfirmationDialog"
    ></confirmation-returns>
    <product-return-reason-dialog
      v-if="dialogProductReturnReason"
      v-model="dialogProductReturnReason"
      :previousReason="targetItem.return_reason"
      :choices="returnReasons"
      @confirm="validateReturnReason"
    ></product-return-reason-dialog>
  </v-container>
</template>

<script>
import ProductReturnReasonDialog from "./ReturnReasonModal";
import ConfirmationReturns from "./ConfirmationReturns";
import ReturnDetail from "../components/ReturnDetail"
import moment from "moment"

export default {
  name: "CreateReturns",
  components: { ConfirmationReturns, ProductReturnReasonDialog, ReturnDetail},
  data() {
    const languages = [{text: 'FRA', value: 'fr'}, {text: 'ENG', value: 'en'}]
    let forcedLocale = new URLSearchParams(window.location.search).get('locale')
    let forcedLanguage
    if (typeof forcedLocale === "string"){
      forcedLanguage = forcedLocale.substr(0, 2)
    }
    forcedLanguage = languages.some(language => language.value == forcedLanguage) ? forcedLanguage : 'fr'
    return {
      showConfirmationDialog: false,
      dialogProductReturnReason: false,
      targetItem: undefined,
      returnReasons: [
        { text: this.$t("return.styleDoesNotSuitMe"), value: "style_does_not_suit_me" },
        { text: this.$t("return.productTooBig"), value: "product_too_big" },
        { text: this.$t("return.productTooSmall"), value: "product_too_small" },
        { text: this.$t("return.productNotCorrespondingToThePhoto"), value: "product_not_corresponding_to_the_photo" },
        { text: this.$t("return.lateDelivery"), value: "late_delivery" },
        { text: this.$t("return.disappointingQuality"), value: "disappointing_quality" },
        { text: this.$t("return.severalSizesOrdered"), value: "several_sizes_ordered" },
        { text: this.$t("return.defectiveProduct"), value: "defective_product" },
        { text: this.$t("return.other"), value: "other" },
      ],
      order: undefined,
      loading: false,
      returnSummary: undefined,
      orderNotFound: false,
      defaultImage: require("../assets/img/products/product_default.png"),
      languages: languages,
      locale: forcedLanguage ? forcedLanguage : navigator.language.substr(0, 2),
      returnSettings: {},
      blockReturnCreation: false
    };
  },
  mounted() {
    this.getOrder()
  },
  methods: {
    getOrder() {
      // get the order
      let path = "/v1/public/orders/" + encodeURIComponent(this.$route.params.id);
      this.$Amplify.API.get("orders", path, {})
        .then(async (res) => {
          for (let item of res.items) {
            item.return_reason = "";
            item.product_sale_price = this.convertPrice(item.product_sale_price, res.currency)
            if(item.product_default_price){
              item.product_default_price = this.convertPrice(item.product_default_price, res.currency)
            }
            if(item.product_sale_price_exclude_tax){
              item.product_sale_price_exclude_tax = this.convertPrice(item.product_sale_price_exclude_tax, res.currency)
            }
            if(item.returned_quantity > 0){
              item.returnable_quantity = item.validated_quantity - item.returned_quantity
              item.returned_quantity_to_display = item.returned_quantity
              item.returned_quantity = undefined
            }
            else {
              item.returnable_quantity = item.validated_quantity
            }
            item.return_quantity = 0;
          }
          res.carrier.cost = this.convertPrice(res.carrier.cost, res.currency)
          this.getSettings(res)
          .then(() => {
              this.order = res;
            }).catch(()=> {
              this.order = res;
            })


        })
        .catch((e) => {
          console.log(e)
          this.$router.push('/')
        });
    },
    getNbItems(productQuantity) {
      // get quantity of an item
      var nbItems = [];
      for (var i = 0; i <= productQuantity; i++) {
        nbItems.push(i);
      }
      return nbItems;
    },
    convertPrice(value, currency) {
      const priceMultiplier = currency
        ? Math.pow(10, currency.minor_unit)
        : 100;
      return value / priceMultiplier;
    },
    getReturnReasonTraduction(returnReasonValue) {
      var returnReason = this.returnReasons.find(
        (returnReason) => returnReason.value == returnReasonValue
      );
      if (returnReason) {
        return returnReason.text;
      } else return returnReasonValue;
    },
    openConfirmationDialog() {
      if (this.selectedItems.length != 0) {
        this.showConfirmationDialog = true;
      } else {
        this.showConfirmationDialog = false;
      }
    },
    closeConfirmationDialog() {
      this.showConfirmationDialog = false;
    },
    openProductReturnReasonModal(item) {
      this.targetItem = item;
      this.dialogProductReturnReason = true;
    },
    closeProductReturnReasonModal() {
      this.targetItem = undefined;
      this.dialogProductReturnReason = false;
    },
    validateReturnReason(productReturnReason) {
      this.$set(this.targetItem, "return_reason", productReturnReason);
      this.closeProductReturnReasonModal();
    },
    sendAllData() {
      this.loading = true
      this.showConfirmationDialog = false;
      let returnOrder = {}
      let refundReturn = this.refund
      let currency = this.order.currency ? this.order.currency.code : this.order.currency_code;
      //TODO implements setting to chose default creation status
      // let currentStatus = "waiting";
      let currentStatus = "waiting_products_receipt";
      returnOrder = {
        order_id: this.order.order_id,
        client_access_key: this.$route.params.id,
        date: new Date(),
        currency_code: currency,
        current_status: currentStatus,
        amount: refundReturn,
        cancel_reason: "",
        status_history: [
          {
            label_status: currentStatus,
            date: new Date(),
            status_comment: "",
          },
        ],
        items: this.selectedItems,
        customer: this.order.customer,
        shipping_address: this.order.shipping_address,
        billing_address: this.order.billing_address,
        transport_refund_amount: this.refundTransport ? this.order.carrier.cost : undefined
      };

      let myInit = {
        body: returnOrder,
      };
      this.$Amplify.API.post("returns", "/v1/public/returns", myInit)
      .then((res) => {
          this.returnSummary = res
          this.loading = false
        })
        .catch((e) => {
          alert(e)
          this.loading = false
        })
    },
    getNbProducts() {
      // get total of products
      let nbProduct = 0;
      for (let item of this.order.items) {
        nbProduct += item.validated_quantity;
      }
      return nbProduct;
    },
    getCarrierTypeTranslation(orderCarrierType){
      const carrierTypes =   [
        { text: this.$t("order.homeDelivery"), value: "home_delivery" },
        { text: this.$t("order.storeDelivery"), value: "store_delivery"},
        { text: this.$t("order.relayPointDelivery"), value: "relay_point_delivery"},
        { text: this.$t("order.expressHomeDelivery"), value: "express_home_delivery"},
      ]
      const carrierTypeTranslation = carrierTypes.find(carrierType => carrierType.value == orderCarrierType)
      if(carrierTypeTranslation){
        return carrierTypeTranslation.text
      } else {
        return orderCarrierType
      }
    },
    getStatus(orderStatus){
      const statusValues = [
        {text:this.$t('order.delivered'), value:'delivered'},
        {text:this.$t('order.deliveredPartially'), value:'partially_delivered'},
        {text:this.$t('order.cancelled'), value:'cancelled'}
      ]
      const status = statusValues.find(status => status.value == orderStatus)
      if(status){
        return status.text
      }
      return orderStatus
    },
    reloadPage(){
      this.loading = true
      location.href = location.origin + location.pathname + `?locale=${this.locale}`
    },
    getSettings (order) {
      let path = "/v1/public/settings/" + encodeURIComponent(this.$route.params.id);
      return this.$Amplify.API.get("returns", path, {})
      .then((res) => {
        this.returnSettings = res
        let deliveryDate = order.status_history.find((item) => item.label_status === "delivered")
        if(deliveryDate){
          if(this.returnSettings.block_return_access){
            const blockAccessReturn = moment(deliveryDate.date).add(this.returnSettings.block_return_access.delay, this.returnSettings.block_return_access.duration)
            if(moment(new Date()).isAfter(blockAccessReturn)){
              this.$router.push({name: "AccessBlocked"})
            }
          }
          if(this.returnSettings.block_return_creation){
            const blockReturnCreationLimit = moment(deliveryDate.date).add(this.returnSettings.block_return_creation.delay, this.returnSettings.block_return_creation.duration)
            if(moment(new Date).isAfter(blockReturnCreationLimit)){
              this.blockReturnCreation = true
            }
          }
        }
      }).catch((e) => {
        console.log(e)
      })
    }
  },
  computed: {
    refund() {
      let refund = 0;
      for (let item of this.selectedItems) {
        refund += item.product_sale_price * item.return_quantity;
      }
      if(this.refundTransport){
        return refund + this.order.carrier.cost
      }
      return refund ;
    },
    selectedItems() {
      return this.order.items.filter((item) => item.return_quantity > 0);
    },
    returnableProducts() {
      return this.order.items.filter((item) => item.returnable_quantity > 0);
    },

    alreadyReturnedProducts() {
      return this.order.items.filter((item) => item.returnable_quantity < item.validated_quantity);
    },
    missingReason() {
      for (let item of this.selectedItems) {
        if (item.return_reason == "") {
          return true;
        }
      }
      return false;
    },
    refundTransport(){
      let totalReturnableQuantity = 0
      for(let item of this.order.items){
        totalReturnableQuantity += item.returnable_quantity
        let selectedItem = this.selectedItems.find(selectedItem => selectedItem.product_ean == item.product_ean)
        if(selectedItem){
          totalReturnableQuantity -= selectedItem.return_quantity
        }
      }
      return totalReturnableQuantity <= 0
    }
  },
};
</script>

<style scoped>
  .mid-row {
    border-bottom: none !important;
  }
  .returned-text {
    color: rgb(0 0 0 / 38%) !important;
  }
  #summary-table tr:hover:not(.v-table__expanded__content) {
    background: transparent !important;
  }
</style>
