import Vue from 'vue'
import VueRouter from 'vue-router'
import CreateReturns from '../views/CreateReturns.vue'
import PageNotFound from '../views/PageNotFound.vue'
import BlockAccess from '../views/BlockAccess.vue'

Vue.use(VueRouter)

const routes = [

  {
    path: "/orders/:id",
    name: 'CreateReturns',
    component: CreateReturns
  },
  {
    path: "/access_blocked",
    name: "AccessBlocked",
    component: BlockAccess
  },
  {
    path :'*',
    component: PageNotFound
  }
  // {
  //   // path: '/about',
  //   // name: 'About',
  //   // // route level code-splitting
  //   // // this generates a separate chunk (about.[hash].js) for this route
  //   // // which is lazy-loaded when the route is visited.
  //   // component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  // }
]

const router = new VueRouter({
  mode: 'history',
  routes
})

export default router
